import palette from '../palette';
export default {
    styleOverrides:{
        root: {
         color: palette.secondary.contrastText,
         backgroundColor: palette.secondary.main
        },
        colorDefault: {
          color: palette.primary.contrastText,
          backgroundColor: palette.primary.main
        },
        userProfile: {
          color: palette.secondary.contrastText,
          backgroundColor: palette.secondary.main
        }
    }
};
