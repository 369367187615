import React, {useEffect, useContext, useState} from 'react';
import { ThemeProvider } from '@material-ui/styles';
import styles from './styles';
import {theme} from './theme';
import { Link as RouterLink } from 'react-router-dom';

import { AppBar,
    Toolbar,
    Badge,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Container,
    Typography,
    CssBaseline} from '@material-ui/core';
import {
  Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from "react-router-dom";
import { createBrowserHistory } from 'history';
import { makeStyles, useTheme } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ImageListItem from '@material-ui/core/ImageListItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import SendIcon from '@material-ui/icons/Send';

const browserHistory = createBrowserHistory({
                                            //forceRefresh: true
                                            });
const useStyles = makeStyles(theme => ({
    root: {
      boxShadow: 'none'
    },
    flexGrow: {
      flexGrow: 1
    },
    navbarDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`
    },
    navDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `white`
    },
    card: {
//        maxWidth: 345,
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      },
    boxPadding: {
    [theme.breakpoints.up('md')]: {
          backgroundColor: 'red',
        },
    }
}));

const App = (props) =>{
    return (
        <ThemeProvider theme={theme}>
            <Home />
        </ThemeProvider>
    )
}
const Home = (props) =>{
   const classes = useStyles();
   const theme = useTheme();
   const smUp = useMediaQuery(theme.breakpoints.up('sm'));

   const padding = smUp?4:0;
   const margin = smUp?1:0;
   const spacing = smUp?10:3;
   console.log("smUp: " + smUp)
   const shipperSteps = [
                         "Find Matching Trucks for your Shipment",
                         "Negotiate and Confirm",
                         "Track the Shipment",
                         "Confirm Goods received",
                         "Approve Payment"]
   return (

            <Router history={browserHistory}>
               <Box component="div" display="block"  m={margin} p={padding}>
                <AppBar position="sticky" elevation={0}>
                     <Container >
                          <Toolbar>
                            <RouterLink to="/">
                              <img width="200" height="100"
                                src="/images/logo.svg"
                              />
                            </RouterLink>
                          </Toolbar>
                     </Container>
                  </AppBar>
                 </Box>
                 <Container>
                 <Box component="div" display="block"  m={margin} p={padding}>
                    <Grid container  direction={'column'} spacing={10}>
                      <Grid item container spacing={10} justify="center"  alignItems="center">
                        <Grid item xs={12} sm={5}>
                            <Typography align='center' variant="h1" component="h1" gutterBottom color='secondary' paragraph='true'>
                                Find Trucks for your shipments!
                            </Typography>
                            <Typography align='center' variant="h3" gutterBottom  paragraph='true'>
                                Access hundreds of trucks and track your shipment all the way through.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} fullWidth>
                            <ImageListItem>
                            <img src="/images/trucks.jpeg"/>
                            </ImageListItem>
                        </Grid>
                       </Grid>
                        <Grid item container spacing={1} justify="center"  alignItems="center">
                            <Grid item xs={12}>
                                <Typography align='left' variant="h1" component="h1" gutterBottom color='secondary' paragraph='true'>
                                    How it works <HelpOutlinedIcon fontSize="large" color="secondary"/>

                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align='center' variant="h3" gutterBottom paragraph='true'>
                                    <Grid container  direction={'column'} spacing={0}>
                                        {shipperSteps.map(s=>
                                        (
                                        <Grid item>
                                            <div style={{alignItems: 'center'}}>

                                                <Typography align='left' variant="h3" gutterBottom><SendIcon  fontSize="large"/>{s}</Typography>
                                            </div>
                                        </Grid>
                                        )
                                        )}
                                    </Grid>
                                </Typography>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Box>

                    <Box component="div" display="block"  m={margin} p={padding}>
                        <Toolbar>
                      <Grid container spacing={spacing/2}>
                      <Grid item>
                      <img width="300" height="150"
                        src="/images/logo-noname.svg"
                      />
                      </Grid>
                      <div className={classes.flexGrow} />
                      <Grid item justify="flex-end">
                        <Typography align='left' variant="h3" gutterBottom color='secondary' paragraph='true'>
                        Contact Us
                        </Typography>
                        <Typography align='left' gutterBottom color='secondary'>
                          support@lorrylinks.com
                        </Typography>
                      </Grid>
                      </Grid>
                      </Toolbar>
                    </Box>
                    </Container>
            </Router>
   )
}

export default App;
