import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import components from './overrides';

const theme = createMuiTheme({
  palette,
  typography,
  components,
  breakpoints: {
      // Define custom breakpoint values.
      // These will apply to Material-UI components that use responsive
      // breakpoints, such as `Grid` and `Hidden`. You can also use the
      // theme breakpoint functions `up`, `down`, and `between` to create
      // media queries for these breakpoints
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1140,
        xl: 1140
      }
  }
});

const webDesignerTheme = createMuiTheme({
  palette,
  typography,
  components,
  breakpoints: {
      // Define custom breakpoint values.
      // These will apply to Material-UI components that use responsive
      // breakpoints, such as `Grid` and `Hidden`. You can also use the
      // theme breakpoint functions `up`, `down`, and `between` to create
      // media queries for these breakpoints
      values: {
        xs: 0,
        sm: 400,
        md: 760,
        lg: 1200,
        xl: 1600
      }
  }
});




export {theme, webDesignerTheme};
